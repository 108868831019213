import Page from "../components/containers/Page.jsx";
import TextInput from "../components/controls/TextInput.jsx";
import { useGlobalStateContext } from "../contexts/GlobalContext/index.jsx";
import useValidation from "../hooks/useValidation.js";
import routes from "../routes.js";
import isEmail from "validator/lib/isEmail";
import { validateAppointmentType } from "../validations";
import { useState, useEffect } from "react";
import {
  API_DOMAIN,
  AUTH_COOKIE_EXP,
  LOGIN_PAGE_MESSAGE,
  NEW_CARE_CLIENT_MESSAGE,
  RETURNING_CARE_CLIENT_MESSAGE,
} from "../const";
import { Link, useHistory, Redirect } from "react-router-dom";
import ServiceIndicator from "../components/ServiceIndicator";
import { storeUserAuthToken } from "../utils.js";
import axios from "axios";
import GoogleBtn from "../components/GoogleBtn/GoogleBtn.js";
import IdleDetectionAlert from "../components/IdleDetectionAlert.jsx";
import authAxios from "../authAxios.js";

const Register = () => {
  const { state, setClientAuthData, setAuthToken, setLoginWith, setNewUser } =
    useGlobalStateContext();
  const { replace } = useHistory();

  const history = useHistory();
  const [isSending, setIsSending] = useState(false);
  const [email, setEmail] = useState("");
  const [emailExistError, setEmailExistError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [error, setError] = useState(null);
  const [isContinuingWithGoogle, setIsContinuingWithGoogle] = useState(false);
  const location = state.selectedLocation;
  const { appointmentType } = state;

  useValidation({
    checkPrerequisiteState(state) {
      const isTypeSet = validateAppointmentType(state);
      if (isTypeSet.error) replace(isTypeSet.path);
    },
    hide: true,
  });

  const registerHandler = () => {
    setIsSending(true);
    setEmailExistError(null);
    setSuccessMessage(null);

    localStorage.removeItem("EXPIRES_TIME");
    localStorage.removeItem("loginWith");

    axios
      .post(`${API_DOMAIN}/v1/register`, { email })
      .then((resp) => {
        setIsSending(false);
        const client = {
          email: email,
        };
        //   storeUserAuthToken(resp.data.token, "", true);
        authAxios.defaults.headers.Authorization = resp.data.token;
        setNewUser({ newUser: true });
        setLoginWith({ loginWith: "email" });
        setClientAuthData({ client, userIsAuthed: false });
        history.push(routes.petSelect);
      })
      .catch((err) => {
        setEmailExistError(err?.response?.data?.response?.message);
        setIsSending(false);
      });
  };

  useEffect(() => {
    setEmail(email);
  }, [email]);

  useEffect(() => {
    document.body.classList.add("signup_body");

    return function cleanup() {
      document.body.classList.remove("signup_body");
    };
  }, []);

  const buttonClickHandler = (response) => {
    responseonSuccessGoogle(response);
  };

  const responseonSuccessGoogle = (resp) => {
    setIsContinuingWithGoogle(true);

    if (resp) {
      let tokenid = resp;
      axios
        .post(`${API_DOMAIN}/v1/google-register`, { token_id: tokenid })
        .then((res) => {
          const response = res.data;

          setIsContinuingWithGoogle(null);
          if (response.status === 200) {
            if (response.token) {
              storeUserAuthToken(response.token, AUTH_COOKIE_EXP, "email");
              setAuthToken({ authToken: response.token });
              setLoginWith({ loginWith: "email" });

              const client = {
                email: response.user_info.email,
                givenName: response.user_info.givenName,
                familyName: response.user_info.familyName,
                phone: response.user_info.phone,
              };
              setClientAuthData({ client });
              history.push(routes.petSelect);
            }
          } else {
            setError(response.message);
          }

          if (response.status === 400) {
            setError(response.message);
          } else {
            setError("Something went wrong!");
          }
        })
        .catch((error) => {
          setError(error?.response?.data.message);
          setIsContinuingWithGoogle(false);
          console.log("Error", error);
        });
    }
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        event.stopPropagation();

        if (isEmail(email) && !isSending) registerHandler();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [email, isSending]);

  if (state.userIsAuthed) return <Redirect to={routes.authSync} />;

  const idleMessageAlert = state?.authToken
    ? RETURNING_CARE_CLIENT_MESSAGE(location)
    : NEW_CARE_CLIENT_MESSAGE(location);

  return (
    <>
      {appointmentType === "care" ? (
        <IdleDetectionAlert messageAlert={idleMessageAlert} />
      ) : (
        <IdleDetectionAlert messageAlert={LOGIN_PAGE_MESSAGE(location)} />
      )}
      <Page title="User registration">
        <div className="add_pet">
          <ServiceIndicator appointmentType={state.appointmentType} />
        </div>
        <div className="sign_in_page">
          <div class="md:text-center">
            <h3 className="heading_two">
              You’re about to have one happy pet.
              <br />
              Let’s get you logged in to set up your appointment.
            </h3>
          </div>

          <div className="sign_in_forms">
            <div className="text-center sign_in_header sign_up_header">
              <div className="users_btn">
                <Link
                  to={routes.signIn}
                  className="block-style py-2 px-4 u_btn "
                >
                  Returning User
                </Link>

                <Link
                  to={routes.register}
                  className="block-style py-2 px-4 u_btn returning_active"
                >
                  New User
                </Link>
              </div>
              <div className="grid-button-google d-grid">
                <GoogleBtn onbtnclick={buttonClickHandler} />
                <br />
                <br />
                {error && <p className="email_error">{error}</p>}
              </div>

              <div className="or_outer">
                <span className="lines left"></span>
                <span className="or_line">OR</span>
                <span className="lines right"></span>
              </div>
              <TextInput
                id="email"
                type="email"
                placeholder="email@example.com"
                onChange={(value) => {
                  setEmail(value);
                }}
                value={email}
                style={emailExistError ? { borderColor: "red" } : null}
              >
                <TextInput.Label>
                  Email<span className="required_input">*</span>
                </TextInput.Label>
                <TextInput.ErrorMessage>
                  Please enter a valid email address.
                </TextInput.ErrorMessage>
              </TextInput>
              {emailExistError && (
                <span className="email_error">{emailExistError}</span>
              )}
            </div>
          </div>

          <div className="success_message">{successMessage}</div>
          <div className="sign_in_btn_save">
            <button
              disabled={isSending}
              onClick={registerHandler}
              type="button"
              className={`inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-primary-500 border border-transparent rounded-md hover:bg-primary-200 hover:text-black focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primary-500 ${!isEmail(email) ? "cursor-not-allowed opacity-50" : ""
                }`}
            >
              {isSending ? "Continuing..." : "Continue"}
            </button>
          </div>
        </div>
        <div style={{ paddingBottom: "70px" }}></div>
      </Page>
    </>
  );
};

export default Register;
