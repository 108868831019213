import sortedIndexOf from "lodash/sortedIndexOf";
import { SESSION_STORAGE_KEYS } from "../const";
import { pushToGTM } from "../gtm";
import routes from "../routes";
import { errorObject } from "./utils";

let mappedBreeds = {};
/**
 *
 * @param {string} species
 * @returns string[]
 */
export function loadBreeds(species = "") {
	console.log("sortedindexof mapped breeds start", mappedBreeds);
	if (!species) return [];
	if (mappedBreeds[species]) {
		return mappedBreeds[species];
	}

	const json = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.BREEDS));
	if (!json) return [];

	Object.keys(json).forEach((key) => {
		mappedBreeds[key] = json[key].map((b) => b.value.toLowerCase());
	});

	return mappedBreeds[species];
}

export function canBreedBook3PM(species, breed) {
	if (!species || !breed) return false;
	const json = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.BREEDS));
	if (!json) return false;
	console.log("THE JSON", json);

	console.log(json[species]);
	const canBook = json[species].reduce((acc, curr) => {
		if (curr.name === breed) return curr.can_book_3pm;
		return acc || false;
	}, false);
	console.log(`can ${breed} book?`, canBook);
	return canBook;
}

export const validatePet = (pet, fullValidation = false) => {
	const errors = {};
	// Per HAP-434, if we have a "full" pet, we are skipping _ALL_ validation
	// steps so that lack of VS data cannot influence anything
	if (!fullValidation) {
		return errorObject(errors);
	}

	if (!pet.name) errors.name = true;
	if (!pet.species) errors.species = true;
	const mappedBreeds = loadBreeds(pet.species);
	/*
	console.log("pet.breed---", pet.breed);
	console.log("mappedBreeds--->>", mappedBreeds.includes(pet.breed?.toLowerCase()));
	console.log(sortedIndexOf(mappedBreeds, pet.breed?.toLowerCase()))
	*/
	//sortedIndexOf(mappedBreeds, pet.breed?.toLowerCase()) < 0

	if (
		(pet.species === "canine" || pet.species === "feline") && !mappedBreeds.includes(pet.breed?.toLowerCase())
	)
		errors.breed = true;
	// if (!pet.species && !pet.breed) errors.breed = true;

	if (!pet.sex) errors.sex = true;
	if (pet.neutered === undefined) errors.neutered = true;

	if (!pet.latestWeight) errors.latestWeight = true;

	// birthday
	if (!pet.birthDay) errors.birthDay = true;
	if (!pet.birthMonth) errors.birthMonth = true;
	if (!pet.birthYear) errors.birthYear = true;

	if (!errors.birthDay && !errors.birthMonth && !errors.birthYear) {
		const petBirthday = Date.parse(
			`${pet.birthMonth}/${pet.birthDay}/${pet.birthYear}`
		);

		if (isNaN(petBirthday)) {
			errors.invalidBirthdayDate = true;
		} else if (new Date() - petBirthday < 0) {
			errors.invalidBirthdayDate = true;
		} else if (`${pet.birthYear}` < 1950) {
			errors.invalidBirthdayDate = true;
		} else if (
			`${pet.birthDay}` >
			new Date(`${pet.birthYear}`, `${pet.birthMonth}`, 0).getDate()
		) {
			errors.invalidBirthdayDate = true;
		}
	}

	console.log("errors---->>>>", errors);


	return errorObject(errors);
};

export const validatePetSelect = ({
	patients,
	selectedPatients,
	appointmentType,
	appointmentTypeId,
	appointmentReason,
}) => {
	let hasError = false;
	const errorList = {
		empty: false,
		petsAffected: [],
		petErrors: [],
		careReason: false,
	};

	const petKeys = Object.keys(patients);
	// we have no pets
	if (selectedPatients.length === 0) {
		hasError = true;
		errorList.empty = true;
	}

	// pets
	selectedPatients.forEach((key) => {
		const pet = patients[key];
		const petErrors = validatePet(pet);
		if (petErrors.error) {
			errorList.petsAffected.push(key);
			errorList.petErrors.push(petErrors);
			hasError = true;
		}
	});

	if (appointmentType === "care" && !appointmentTypeId) {
		errorList.careReason = true;
		hasError = true;
	}

	if (appointmentType === "care" && !appointmentReason) {
		errorList.appointmentReason = true;
		hasError = true;
	}

	return {
		error: hasError,
		errors: errorList,
		path: routes.petSelect,
	};
};
