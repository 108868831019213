import { usePaginationContext } from "./contexts/PaginationContext/index.jsx";
import HeartandpawLogo from "./assets/images/Heartandpaw-logo.svg";
import Close from "./assets/images/Close.svg";
import Service_active_v1 from "./assets/images/header/progress/service_active_v1.svg";
import Service_deactive_v1 from "./assets/images/header/progress/service_deactive_v1.svg";
import Service_active_v2 from "./assets/images/header/progress/service_active_v2.svg";
import Service_deactive_v2 from "./assets/images/header/progress/service_deactive_v2.svg";
import Service_active_v3 from "./assets/images/header/progress/service_active_v3.svg";
import Service_deactive_v3 from "./assets/images/header/progress/service_deactive_v3.svg";
import Service_active_v4 from "./assets/images/header/progress/service_active_v4.svg";
import Service_deactive_v4 from "./assets/images/header/progress/service_deactive_v4.svg";
import Service_active_v5 from "./assets/images/header/progress/service_active_v5.svg";
import service_deactive_v5 from "./assets/images/header/progress/complete.svg";
import ROUTES from "./routes.js";
import { Link } from "react-router-dom";
import { paramsOnLoad } from "./utils.js";
import { useGlobalStateContext } from "./contexts/GlobalContext/index.jsx";

const Header = () => {
  const { state } = useGlobalStateContext();
  const { appointmentType } = state;
  const isIframe = paramsOnLoad.get("isIframe");

  const { paginationState } = usePaginationContext();

  const closeTabHandler = () => {
    if (window.confirm("Do you want to close window?")) {
      window.close();
    }
  };
  return (
    <div className="header">
      {isIframe !== "true" && (
        <>
          <div className="logo">
            <a href={ROUTES.type}>
              <img src={HeartandpawLogo} className="ran" />
            </a>
          </div>

          <div className="header-right">
            <button type="button" onClick={closeTabHandler}>
              <img src={Close} className="im" />
            </button>
          </div>
        </>
      )}
      {paginationState.completedPercent > 90 ? (
        ""
      ) : state.appointmentType == "care" ? (
        <div className="progress">
          <ul>
            <li className="progress_img suber_active">
              <img className="active" src={Service_active_v1} />
              <img className="deactive" src={Service_deactive_v1} />
              <p>Select a Service</p>
            </li>
            <div className="Inline"></div>
            <li
              className={
                "progress_img" +
                `${paginationState.completedPercent >= 20 ? " suber_active" : ""
                }`
              }
            >
              <img className="active" src={Service_active_v4} />
              <img className="deactive" src={Service_deactive_v4} />
              <p>Select an Appointment</p>
            </li>
            <div className="Inline"></div>
            <li
              className={
                "progress_img" +
                `${paginationState.completedPercent >= 55 ? " suber_active" : ""
                }`
              }
            >
              <img className="active" src={Service_active_v3} />
              <img className="deactive" src={Service_deactive_v3} />
              <p>Pet Information</p>
            </li>
            <div className="Inline"></div>
            <li
              className={
                "progress_img" +
                `${paginationState.completedPercent >= 80 ? " suber_active" : ""
                }`
              }
            >
              <img className="active" src={Service_active_v2} />
              <img className="deactive" src={Service_deactive_v2} />
              <p> Contact Information</p>
            </li>
            <div className="Inline"></div>
            <li className="progress_img">
              <img className="active" src={Service_active_v5} />
              <img className="deactive" src={service_deactive_v5} />
              <p> Complete Appointment</p>
            </li>
          </ul>
        </div>
      ) : (
        <div className="progress">
          <ul>
            <li className="progress_img suber_active">
              <img className="active" src={Service_active_v1} />
              <img className="deactive" src={Service_deactive_v1} />
              <p>Select a Service</p>
            </li>
            <div className="Inline"></div>
            <li
              className={
                "progress_img" +
                `${paginationState.completedPercent >= 20 ? " suber_active" : ""
                }`
              }
            >
              <img className="active" src={Service_active_v3} />
              <img className="deactive" src={Service_deactive_v3} />
              <p>Pet Information</p>
            </li>
            <div className="Inline"></div>
            <li
              className={
                "progress_img" +
                `${paginationState.completedPercent >= 55 ? " suber_active" : ""
                }`
              }
            >
              <img className="active" src={Service_active_v4} />
              <img className="deactive" src={Service_deactive_v4} />
              <p>Select an Appointment</p>
            </li>
            <div className="Inline"></div>
            <li
              className={
                "progress_img" +
                `${paginationState.completedPercent >= 80 ? " suber_active" : ""
                }`
              }
            >
              <img className="active" src={Service_active_v2} />
              <img className="deactive" src={Service_deactive_v2} />
              <p> Contact Information</p>
            </li>
            <div className="Inline"></div>
            <li className="progress_img">
              <img className="active" src={Service_active_v5} />
              <img className="deactive" src={service_deactive_v5} />
              <p> Complete Appointment</p>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Header;
