import { useReducer, createContext, useContext, useMemo } from "react";
import reducer from "./reducer";
import initialState from "./initialState";
import actions from "./actions";
import { API_DOMAIN } from "../../const";
import { fetchWithCredentials } from "../../utils";
import { useLocation } from "react-router";
import authAxios from "../../authAxios";
const GlobalStateContext = createContext();

/**
 *
 * Contains all of the dispatch functions for the reducer
 * (based off Kent C Dodds blog posts such as: https://kentcdodds.com/blog/how-to-use-react-context-effectively)
 *
 * @returns {
 *  state: initialState,
 *  actions: actions,
 * }
 */
const useGlobalStateContext = () => {
  const { search } = useLocation();
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error("[Global Context]: Used outside of an GlobalStateProvider");
  }
  const [state, dispatch] = context;

  return {
    state,
    dispatch,
    actions,

    // all of our "action creators"
    setAppointmentType({ type, id = "" }) {
      dispatch({
        type: actions.SET_TYPE_ID,
        payload: { appointmentType: type, appointmentTypeId: id },
      });
    },
    setCareAndTypesData({ careTypes, appointmentTypes }) {
      dispatch({
        type: actions.SET_API_DATA,
        payload: {
          careTypes,
          appointmentTypes,
        },
      });
    },
    setSpayedNeutered({ spayed, petIdx }) {
      dispatch({
        type: actions.SET_SPAYED_NEUTERED,
        payload: {
          spayed,
          petIdx,
        },
      });
    },
    setGender({ gender, petIdx }) {
      dispatch({
        type: actions.SET_GENDER,
        payload: {
          gender,
          petIdx,
        },
      });
    },
    setLocations({ searchAddress }) {
      dispatch({
        type: actions.SET_LOCATION,
        payload: {
          searchAddress,
        },
      });
    },
    setBreed({ breed, petIdx }) {
      dispatch({
        type: actions.SET_BREED,
        payload: {
          breed,
          petIdx,
        },
      });
    },
    setSpecies({ species, speciesItem, petIdx, wasDropdown = false }) {
      dispatch({
        type: actions.SET_SPECIES,
        payload: {
          species,
          petIdx,
          wasDropdown,
          speciesItem,
        },
      });
    },
    setAppointmentSlot({ appointment, location }) {
      dispatch({
        type: actions.SET_APPOINTMENT,
        payload: {
          appointment,
          location,
        },
      });
    },

    // pet stuff
    managePet({ id, pet }) {
      dispatch({
        type: actions.MANAGE_PET,
        payload: {
          id,
          pet,
        },
      });
    },
    async createOrUpdatePet({ petInfo = null }) {
      if (!petInfo) throw new Error("Pet info cannot be null.");
      let endpoint = `${API_DOMAIN}/v1/pets`;
      if (petInfo.id.indexOf("uncreated") < 0) endpoint += `/${petInfo.id}`;

      try {
        const response = await authAxios.post(endpoint, petInfo);
        return response.data;
      } catch (err) {
        console.log(err);
        return null;
      }
    },
    async deletePet({ id }) {
      if (id?.indexOf("uncreated") >= 0) {
        dispatch({
          type: actions.DELETE_PET,
          payload: {
            id,
          },
        });
        return true;
      }
      try {
        const response = await fetchWithCredentials(
          `${API_DOMAIN}/v1/pets/${id}`,
          {
            method: "DELETE",
          }
        );
        const data = await response.json();
        if (data.isDeceased) {
          dispatch({
            type: actions.DELETE_PET,
            payload: {
              id,
            },
          });
        }
        return true;
      } catch (err) {
        console.error("[DELETE PET]: Failed to delete pet");
        console.log("[DELETE PET]:", err);
        return false;
      }
    },
    selectPet({ id }) {
      dispatch({
        type: actions.SELECT_PET,
        payload: { id },
      });
    },
    deselectPet({ id }) {
      dispatch({ type: actions.DESELECT_PET, payload: { id } });
    },
    setEmail({ email }) {
      dispatch({ type: actions.SET_EMAIL, payload: { email } });
    },

    setAuthToken({ authToken }) {
      dispatch({ type: actions.SET_AUTH_TOKEN, payload: { authToken } });
    },
    setLoginWith({ loginWith }) {
      dispatch({ type: actions.SET_LOGIN_WITH, payload: { loginWith } });
    },
    setGivenName({ givenName }) {
      dispatch({ type: actions.SET_GIVEN_NAME, payload: { givenName } });
    },
    setFamilyName({ familyName }) {
      dispatch({ type: actions.SET_FAMILY_NAME, payload: { familyName } });
    },
    setPhone({ phone }) {
      dispatch({ type: actions.SET_PHONE_NUMBER, payload: { phone } });
    },
    setCreditInfo(creditcardinfo) {
      dispatch({ type: actions.SET_CREDITCARD_INFO, payload: creditcardinfo });
    },
    setAppointmentRange({ appointmentRange }) {
      dispatch({
        type: actions.SET_APPOINTMENT_RANGE,
        payload: { appointmentRange },
      });
    },
    setGlobalState(state) {
      dispatch({
        type: actions.SET_GLOBAL_STATE,
        payload: state,
      });
    },
    setCareReason({ careReason }) {
      dispatch({
        type: actions.SET_CARE_REASON,
        payload: { careReason },
      });
    },
    // setPreferredProviders({ preferredProviders }) {
    //   dispatch({
    //     type: actions.SET_PREFERRED_PROVIDERS,
    //     payload: { preferredProviders },
    //   });
    // },
    setAddress({ address }) {
      dispatch({
        type: actions.SET_ADDRESS,
        payload: { address },
      });
    },
    setAddOns({ addOn }) {
      dispatch({
        type: actions.SET_ADD_ONS,
        payload: { addOn },
      });
    },
    setServiceOption({ service }) {
      dispatch({
        type: actions.SET_MAIN_SERVICE,
        payload: {
          service,
        },
      });
    },
    setTerms({ terms }) {
      dispatch({
        type: actions.SET_TERMS,
        payload: {
          terms,
        },
      });
    },
    setMessaging({ messaging }) {
      dispatch({
        type: actions.SET_MESSAGING,
        payload: {
          receiveMessaging: messaging,
        },
      });
    },
    setClientHasAuthed({ userIsAuthed }) {
      dispatch({
        type: actions.SET_USER_AUTH,
        payload: {
          userIsAuthed,
        },
      });
    },
    setClientAuthData({ patients, client, userIsAuthed }) {
      dispatch({
        type: actions.SET_CLIENT_AUTH_DATA,
        payload: {
          patients,
          userIsAuthed,
          client,
        },
      });
    },
    setAppointmentReason({ appointmentReason }) {
      dispatch({
        type: actions.SET_APPOINTMENT_REASON,
        payload: {
          appointmentReason,
        },
      });
    },

    setAppointmentReasonOtherText({ appointmentReasonOtherText }) {
      dispatch({
        type: actions.SET_APPOINTMENT_REASON_OTHER,
        payload: {
          appointmentReasonOtherText,
        },
      });
    },

    async fetchClientData(setLocalComponentStatus = () => {}) {
      setLocalComponentStatus("loading");

      const params = new URLSearchParams(search);

      fetch(`${API_DOMAIN}/v1/sync?clientId=${params.get("clientId")}`);
    },
    setGrapevine({ grapevine }) {
      dispatch({
        type: actions.SET_GRAPEVINE,
        payload: {
          grapevine,
        },
      });
    },
    reset() {
      console.log("Resetting all data");
      dispatch({
        type: actions.RESET,
        payload: {},
      });
    },
    setNewUser({ newUser }) {
      dispatch({
        type: actions.SET_NEW_USER,
        payload: {
          newUser,
        },
      });
    },
    setCenterLocation({ centerLocation }) {
      dispatch({
        type: actions.SET_CENTER_LOCATION,
        payload: {
          centerLocation,
        },
      });
    },
  };
};

const GlobalStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = useMemo(() => [state, dispatch], [state]);
  return (
    <GlobalStateContext.Provider value={value}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export { GlobalStateProvider, useGlobalStateContext };
