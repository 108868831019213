/**
 * Initial state pulls data from two separate places
 *
 * 1. Grab data from parameters, if present on load
 * 2. Grab data from session storage, if present on load
 *
 * We do this because it helps ensure  we have data
 * after we authenticate with providers
 */

import CryptoJS from "crypto-js";

import { AUTH_COOKIE_EXP, SECSECRET_KEY_AUTH_TOKEN, SESSION_STORAGE_KEYS } from "../../const";
import {
  retrieveFromSession,
  getDefaultAppointmentRange,
  getGroomingAppointmentRange,
  paramsOnLoad,
  storeUserAuthToken,
  getCookie,
} from "../../utils";

const savedAppointmentTypes = retrieveFromSession(
  SESSION_STORAGE_KEYS.APPOINTMENT_TYPES
);

const token = paramsOnLoad.get("token");
const loginType = paramsOnLoad.get("loginType");
const petId = paramsOnLoad.get("petId");
const isIframe = paramsOnLoad.get("isIframe");
const refereeId = paramsOnLoad.get("refereeId");
const isMedicalReminder = paramsOnLoad.get("medical_reminder");

let loginWith;
if (token) {
  try {
    const bytes = CryptoJS.AES.decrypt(token, SECSECRET_KEY_AUTH_TOKEN);
    const decryptToken = bytes.toString(CryptoJS.enc.Utf8);

    if (decryptToken) {

      loginWith = (loginType === 'phone') ? 'phone' : 'email';
      storeUserAuthToken(decryptToken, AUTH_COOKIE_EXP, loginWith);

    }
  } catch (error) {
    console.log("error", error);
  }
}

const savedCareReasons = retrieveFromSession(SESSION_STORAGE_KEYS.CARE_REASONS);
const authToken = getCookie("authToken");
loginWith = localStorage.getItem("loginWith");

// For  grooming page

let appointmentType = null;
let appointmentTypeId = null;
let appointmentReasonText = null;

if (paramsOnLoad.get("grooming")) {
  appointmentType = "style";
  appointmentTypeId = "389";
}
if (paramsOnLoad.get("type")) {
  const appointmentTypeString = paramsOnLoad.get("type")?.toLocaleLowerCase();
  appointmentTypeId = paramsOnLoad.get("typeId");

  const typeArr = appointmentTypeString?.split("-");

  if (typeArr.length === 1) appointmentType = appointmentTypeString;

  if (appointmentTypeString === "play") {
    appointmentTypeId = "449";
  }

  if (appointmentTypeString === "care" && isIframe === "true" && petId) {
    appointmentTypeId = "235"; //For production
    // appointmentTypeId = '270';
    if (isMedicalReminder) appointmentReasonText = "Vaccination";
  }

  if (appointmentTypeString === "style") {
    appointmentType = "style";
    appointmentTypeId = "389"; //for production

    //appointmentTypeId = "247";
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  userIsAuthed: authToken ? true : false,
  appointmentWasBooked: false,

  // appointment
  appointmentType: appointmentType,
  appointmentTypeId: appointmentTypeId,

  appointmentRange: paramsOnLoad.get("grooming")
    ? getGroomingAppointmentRange()
    : getDefaultAppointmentRange(),
  searchAddress: paramsOnLoad.get("searchAddress")
    ? paramsOnLoad.get("searchAddress")
    : "",
  selectedAppointmentSlot: {},
  selectedLocation: {},
  appointmentSortType: null,

  // type specific options
  serviceOption: null,
  addOns: [],
  appointmentReason: appointmentReasonText ?? null,
  appointmentReasonOtherText: null,
  // preferredProviders: null,

  // dropdown / select  data
  appointmentTypes: savedAppointmentTypes ? savedAppointmentTypes : [],
  careTypes: savedCareReasons ? savedCareReasons : [],

  // patients
  patients: {},
  selectedPatients: [],

  // client
  givenName: "",
  familyName: "",
  email: "",
  phone: "",
  cardconnectExpiry: "",
  cardconnectToken: "",
  // address: {}, // This was removed per HAP's request, but reducer code is left
  grapevine: "", // how did they learn
  receiveMessaging: true,
  terms: false,
  centerLocation: [],
  newUser: false,
  authToken: authToken ? authToken : null,
  loginWith: loginWith ? loginWith : null,
  refereeId: refereeId ? refereeId : null,
};
